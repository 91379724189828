<template>
  <l-map
    ref="ariaMap"
    style="height: 620px; width: 100%"
    :zoom="zoom"
    :minZoom="minZoom"
    :maxBounds="bounds"
    :center="center"
    :options="{ zoomControl: false }"
  >
    <l-tile-layer :url="url"></l-tile-layer>
    <l-control-zoom position="bottomleft"></l-control-zoom>
    <l-control ref="mapControl" position="bottomright">
      <div v-bind:class="mapControlClass">
        <v-tabs v-model="tabIndex" @change="onTabChange()">
          <v-tab @click="onTabClick(0)" key="0">
            <v-icon>mdi-layers</v-icon>
            Stime modellistiche
            <em class="fas fa-sort-up"></em>
            <em class="fas fa-sort-down"></em>
          </v-tab>
          <v-tab @click="onTabClick(1)" key="1">
            <v-icon>mdi-chart-line-variant</v-icon>
            Misure
            <em class="fas fa-sort-up"></em>
            <em class="fas fa-sort-down"></em>
          </v-tab>
          <v-tab-item active eager class="map-tabs-items">
            <ControlStime
              v-show="!hideControls"
              v-on:layerChanged="layerStimeChanged"
              v-on:dateChanged="dateChanged"
              v-on:dataUnavailable="dataStimeUnavailable"
              v-on:dataReady="dataReady"
            ></ControlStime>
          </v-tab-item>
          <v-tab-item eager class="map-tabs-items">
            <ControlMisure
              v-show="!hideControls"
              v-on:layerChanged="layerMisureChanged"
              v-on:dateChanged="dateChanged"
              v-on:dataUnavailable="dataMisureUnavailable"
              v-on:dataMisureError="dataMisureError"
              v-on:dataReady="dataReady"
            ></ControlMisure>
          </v-tab-item>
        </v-tabs>
      </div>
    </l-control>
    <div v-if="tabIndex == 0">
      <l-wms-tile-layer
        ref="wtlObject"
        v-for="layer in layersStime"
        :key="layer.name"
        :base-url="layer.baseUrl"
        :layers="layer.layers"
        :name="layer.name"
        :format="layer.format"
        :transparent="layer.transparent"
        :attribution="layer.attribution"
        :visible="layer.visible"
        version="1.3.0"
        layer-type="base"
      />
    </div>
    <div v-if="tabIndex == 1">
      <l-wms-tile-layer
        ref="wtlObject"
        v-for="layer in layersAggregazioni"
        :key="layer.name"
        :base-url="layer.baseUrl"
        :layers="layer.layers"
        :name="layer.name"
        :format="layer.format"
        :transparent="layer.transparent"
        :attribution="layer.attribution"
        :visible="layer.visible"
        version="1.3.0"
        layer-type="base"
      />
    </div>
    <div v-if="wmsUnavailable" class="wms-error">
      <div class="msg-wms-unavailable">
        <span class="text--primary">
          Servizio temporaneamente<br />non disponibile, ci scusiamo<br />per il
          disagio
        </span>
      </div>
    </div>
    <div v-if="!wmsUnavailable && wmsDataUnavailable" class="wms-error">
      <div class="msg-data-unavailable">
        <span class="text--primary">
          Dati attualmente<br />non disponibili
        </span>
      </div>
    </div>
    <div v-if="!wmsUnavailable && wmsDataMisureError" class="wms-error">
      <div class="msg-data-unavailable">
        <span class="text--primary">
          <div v-if="misureError == 'todayError'">
            Dati validati disponibili solo<br />per i giorni precedenti
          </div>
          <div v-if="misureError == 'futureDayError'">
            Selezionare una data<br />precedente a oggi
          </div>
        </span>
      </div>
    </div>
    <div v-if="!wmsUnavailable && wmsStimeComingSoon" class="wms-error">
      <div class="msg-data-coming-soon">
        <span class="text--primary">
          Stime di ieri in elaborazione.<br />Dati disponibili alle ore 11:00
        </span>
      </div>
    </div>
    <div v-if="!wmsUnavailable && wmsStime12_30" class="wms-error">
      <div class="msg-data-coming-soon">
        <span class="text--primary">
          Dati previsionali disponibili<br />a partire dalle ore 12:30
        </span>
      </div>
    </div>
    <div v-if="!wmsUnavailable && wmsMisureComingSoon" class="wms-error">
      <div class="msg-data-coming-soon">
        <span class="text--primary">
          I dati saranno disponibili<br />a partire dalle ore
          {{ misureTimeLimit }}
        </span>
      </div>
    </div>
  </l-map>
</template>

<script>
import {
  LControl,
  LControlZoom,
  LMap,
  LTileLayer,
  LWMSTileLayer
} from "vue2-leaflet";
import ControlStime from "@/components/mappa/ControlStime";
import ControlMisure from "@/components/mappa/ControlMisure";
import { mixinDetictingMobile } from "../../config/mixinDetictingMobile";
import { EventBus } from "../../EventBus";
import config from "@/config/mappa-inquinanti";
import moment from "moment-timezone";
import { GET_FEATURE_INFO } from "@/store/actions.type";

export default {
  name: "MappaInquinanti",
  components: {
    LControl,
    LControlZoom,
    LMap,
    LTileLayer,
    "l-wms-tile-layer": LWMSTileLayer,
    ControlStime,
    ControlMisure
  },
  mixins: [mixinDetictingMobile],
  data() {
    return {
      mapObject: null,
      wtlObject: null,
      url: config.openStreetMapUrl,
      zoom: config.zoom,
      minZoom: config.minZoom,
      //bounds: config.bounds,
      center: config.center,
      layersStime: config.wmsLayersStime,
      layersAggregazioni: config.wmsLayersAggregazioni,
      controlPosition: "bottomright",
      hideControls: false,
      tabIndex: 0,
      currentDate: new moment().format("YYYY-MM-DD"),
      currentLayer: "Qualita_Aria",
      wmsUnavailable: false,
      wmsDataUnavailable: false,
      wmsStimeComingSoon: false,
      wmsStime12_30: false,
      wmsMisureComingSoon: false,
      wmsDataMisureError: false,
      misureError: "",
      misureTimeLimit: "",
      tileCounter: 0
    };
  },
  computed: {
    bounds: function () {
      // return this.isMobile() ? config.boundsMobile : config.bounds;
      if (this.isMobile()) {
        return config.boundsMobile;
      } else {
        return config.bounds;
      }
    },
    mapControlClass: function () {
      return this.hideControls
        ? "map-control-panel-mobile"
        : "map-control-panel";
    }
  },
  methods: {
    moment: function (dateStr) {
      return moment(dateStr);
    },
    dateChanged(newDate, error) {
      //console.log("dateChanged: " + newDate);
      this.currentDate = newDate;
      if (error == undefined) {
        this.resetErrors();
      }
      //this.setTileListener();
      this.changeDateStime(newDate);
      this.changeDateAggregazioni(newDate);
      this.mapObject.closePopup();
    },
    layerStimeChanged(targetLayer) {
      this.currentLayer = targetLayer;
      //console.log("layerStimeChanged: " + targetLayer);
      this.resetErrors();
      //this.setTileListener();
      this.changeLayerStime(targetLayer);
      this.mapObject.closePopup();
    },
    layerMisureChanged(targetLayer) {
      this.currentLayer = targetLayer;
      //console.log("layerMisureChanged: " + targetLayer);
      if (!this.isSameOrAfterToday(this.currentDate)) {
        this.resetErrors();
      }
      //this.setTileListener();
      this.changeLayerAggregazioni(targetLayer);
      this.mapObject.closePopup();
    },
    changeLayerStime(targetLayer) {
      this.layersStime.forEach(function (item) {
        if (item.name == "BdTre") return;
        item.visible = item.name == targetLayer ? true : false;
      });
    },
    changeLayerAggregazioni(targetLayer) {
      this.layersAggregazioni.forEach(function (item) {
        if (item.name == "BdTre") return;
        item.visible = item.name == targetLayer ? true : false;
      });
    },
    changeDateStime(newDate) {
      let date = moment(newDate).format("YYYY-MM-DD");
      let baseUrl = config.wmsAriaWebUrl + date + config.wmsAriaWebTimeSfx;
      let tmpArray = this.layersStime;
      this.layersStime = this.layersStime.filter((el) => el.name === "BdTre");
      tmpArray.forEach((layer) => {
        if (layer.name !== "BdTre") {
          layer.baseUrl = baseUrl;
          setTimeout(() => {
            this.layersStime.push(layer);
          }, 500);
        }
      });
    },
    changeDateAggregazioni(newDate) {
      let date = moment(newDate).format("YYYY-MM-DD");
      let baseUrl =
        config.wmsAriaWebAggregazioniUrl + date + config.wmsAriaWebTimeSfx;
      let tmpArray = this.layersAggregazioni;
      this.layersAggregazioni = this.layersAggregazioni.filter(
        (el) => el.name === "BdTre"
      );
      tmpArray.forEach((layer) => {
        if (layer.name !== "BdTre") {
          layer.baseUrl = baseUrl;
          setTimeout(() => {
            this.layersAggregazioni.push(layer);
          }, 500);
        }
      });
    },
    dataStimeUnavailable(queryDate) {
      //console.log("dataStimeUnavailable " + queryDate);
      let now = new moment();
      let timeLimit = new moment().hour(11).minutes(0);
      let yesterday = moment(now).subtract(1, "day").format("YYYY-MM-DD");
      if (queryDate === this.today && now.isBefore(timeLimit)) {
        this.wmsStimeComingSoon = true;
      } else if (this.showStime12_30message(queryDate)) {
        this.wmsStime12_30 = true;
      } else {
        this.wmsDataUnavailable = true;
      }
    },
    showStime12_30message(queryDate) {
      let now = new moment();
      let today = moment(now).format("YYYY-MM-DD");
      let hour12_30 = new moment().hour(12).minutes(30);
      let weekDay = moment(now).day();
      let april_5 = moment("2021-04-05").format("YYYY-MM-DD");
      let november_1 = moment("2021-11-01").format("YYYY-MM-DD");
      if (
        queryDate === today &&
        now.isBefore(hour12_30) &&
        weekDay != 0 && //domenica
        weekDay != 6 && //sabato
        today != april_5 &&
        today != november_1
      ) {
        return true;
      }
      return false;
    },
    dataMisureUnavailable(queryDate) {
      //console.log("dataMisureUnavailable " + queryDate);
      let now = new moment();
      let timeLimit = new moment.utc().hour(7).minutes(0);
      let yesterday = moment(now).subtract(1, "day").format("YYYY-MM-DD");
      if (queryDate === yesterday && now.isBefore(timeLimit)) {
        this.misureTimeLimit = "7:00";
        this.wmsMisureComingSoon = true;
      } else {
        this.wmsDataUnavailable = true;
      }
      //this.wmsDataMisureError = false;
    },
    dataMisureError(queryDate, error) {
      //console.log("dataMisureError " + queryDate);
      if (this.tabIndex == 1) {
        //Errore solo se è selezionato il tab misure
        this.wmsDataMisureError = true;
        this.misureError = error;
      }
    },
    dataReady(queryDate) {
      //console.log("dataReady " + queryDate);
      this.wmsDataUnavailable = false;
    },
    resetErrors() {
      this.wmsUnavailable = false;
      this.wmsDataUnavailable = false;
      this.wmsStimeComingSoon = false;
      this.wmsStime12_30 = false;
      this.wmsMisureComingSoon = false;
      this.wmsDataMisureError = false;
    },
    onTabClick(tab) {
      if (this.isMobile() && tab === this.tabIndex) {
        this.hideControls = !this.hideControls;
      }
    },
    onTabChange() {
      this.resetErrors();
      if (this.tabIndex == 0) {
        EventBus.$emit("checkFeatureStime");
        this.changeLayerStime(this.getWmsStime(this.currentLayer));
        this.mapObject.closePopup();
      } else if (this.tabIndex == 1) {
        EventBus.$emit("checkFeatureMisure");
        this.changeLayerAggregazioni(this.getWmsAggreg(this.currentLayer));
      }
    },
    getWmsAggreg(layerName) {
      switch (layerName) {
        case "Stazioni_O3":
          return "Stazioni_O3_A";
        case "Stazioni_NO2":
          return "Stazioni_NO2_A";
        case "Stazioni_PM10":
          return "Stazioni_PM10_A";
        case "Stazioni_PM2_5":
          return "Stazioni_PM2_5_A";
        case "Qualita_Aria":
          return "Stazioni_O3_A";
        default:
          return layerName;
      }
    },
    getWmsStime(layerName) {
      switch (layerName) {
        case "Stazioni_O3_A":
          return "Stazioni_O3";
        case "Stazioni_NO2_A":
          return "Stazioni_NO2";
        case "Stazioni_PM10_A":
          return "Stazioni_PM10";
        case "Stazioni_PM2_5_A":
          return "Stazioni_PM2_5";
        default:
          return layerName;
      }
    },
    isSameOrAfterToday(queryDate) {
      let today = new moment().startOf("day");
      let newDay = new moment(queryDate);
      return newDay.isSameOrAfter(today);
    },
    onMapClick(e) {
      if (this.tabIndex == 1) {
        let time = new moment(this.currentDate).format("YYYY-MM-DD");
        let layer = this.getWmsAggreg(this.currentLayer).replace("_A", "");
        var url = this.getFeatureInfoUrl(e.latlng, time, layer);
        this.$store
          .dispatch(GET_FEATURE_INFO, {
            url: url
          })
          .then((response) => {
            let stazione = this.getDenominazioneStazione(response.data);
            if (stazione) {
              let content =
                "<span style='color: #094067'><strong>Stazione:</strong></span><br/>" +
                stazione;
              let popup = new L.Popup({ maxWidth: 1000 });
              popup.setLatLng(e.latlng);
              popup.setContent(content);
              this.mapObject.openPopup(popup);
            }
          })
          .catch((error) => {
            //console.log("Error", { error });
          });
      }
    },
    getDenominazioneStazione(data) {
      let index = data.indexOf("denominazione = '");
      let stazione = undefined;
      if (index > 0) {
        let dataCut = data.substring(index + 17);
        let pattern = /\n/;
        let index2 = dataCut.search(pattern);
        stazione = dataCut.substring(0, index2 - 1);
      }
      return stazione;
    },
    getFeatureInfoUrl: function (latlng, time, layer) {
      // Construct a GetFeatureInfo request URL given a point
      var point = this.mapObject.latLngToContainerPoint(
          latlng,
          this.mapObject.getZoom()
        ),
        size = this.mapObject.getSize(),
        // this crs is used to show layer added to map
        crs = this.mapObject.options.crs,
        // these are the SouthWest and NorthEast points
        // projected from LatLng into used crs
        sw = crs.project(this.mapObject.getBounds().getSouthWest()),
        ne = crs.project(this.mapObject.getBounds().getNorthEast()),
        params = {
          request: "GetFeatureInfo",
          service: "WMS",
          crs: "EPSG:3857",
          styles: "",
          transparent: true,
          version: "1.3.0",
          format: "image/png",
          // these are bbox defined by SouthWest and NorthEast coords
          bbox: sw.x + "," + sw.y + "," + ne.x + "," + ne.y,
          height: size.y,
          width: size.x,
          layers: layer,
          query_layers: layer,
          info_format: "text/plain",
          i: point.x,
          j: point.y,
          TIME: time
        };
      let url = config.wmsAriaWebAggregazioniUrl.replace("TIME=", "");
      return url + L.Util.getParamString(params, url, true);
    }
    /*tilesLoaded() {
      console.log("ALL TILES LOADED");
      EventBus.$emit("enableControls");
      this.removeTileListener();
    },
    setTileListener() {
      this.tileCounter = 0;
      this.wtlObject = this.$refs.wtlObject;
      this.wtlObject.forEach(layer => {
        var self = this;
        if (layer.name !== "BdTre") {
          layer.mapObject.on("load", this.tilesLoaded);
        }
      });
    },
    removeTileListener() {
      this.tileCounter = 0;
      this.wtlObject = this.$refs.wtlObject;
      this.wtlObject.forEach(layer => {
        var self = this;
        if (layer.name !== "BdTre") {
          layer.mapObject.off("load", this.tilesLoaded);
        }
      });
    }*/
  },
  mounted() {
    if (this.isMobile()) {
      this.controlPosition = "topleft";
      this.hideControls = true;
    }
    this.$nextTick(() => {
      this.mapObject = this.$refs.ariaMap.mapObject;
      this.mapObject.addEventListener("click", this.onMapClick);
      //this.setTileListener();
      var self = this;
      this.wtlObject = this.$refs.wtlObject;
      this.wtlObject.forEach((layer) => {
        var self = this;
        if (layer.name !== "BdTre") {
          layer.mapObject.on("tileerror", function (error, tile) {
            self.wmsUnavailable = true;
          });
        }
      });
    });
  }
};
</script>
