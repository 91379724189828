<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="9">
        <span class="map-control-label" v-if="previsione">Previsioni del</span>
        <span class="map-control-label" v-if="!previsione">
          Valutazioni del
        </span>
      </v-col>
      <v-col cols="3" class="d-flex justify-end">
        <a @click="subtractDay()">
          <v-icon v-if="controlsEnabled && !subtractDayDisabled" color="blue">
            mdi-chevron-left-circle
          </v-icon>
          <v-icon v-if="!controlsEnabled || subtractDayDisabled" color="grey">
            mdi-chevron-left-circle
          </v-icon>
        </a>
        <a @click="addDay()">
          <v-icon v-if="controlsEnabled && !addDayDisabled" color="blue">
            mdi-chevron-right-circle
          </v-icon>
          <v-icon v-if="!controlsEnabled || addDayDisabled" color="grey">
            mdi-chevron-right-circle
          </v-icon>
        </a>
      </v-col>
    </v-row>
    <v-row>
      <v-menu
        v-model="showPicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        z-index="2000"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            filled
            dense
            readonly
            aria-label="Imposta data"
            append-icon="mdi-calendar"
            v-on="on"
            :value="selectedDate"
            :hint="dataEmissioneDesc"
            :persistent-hint="true"
            @click:append="showPicker = !showPicker"
          ></v-text-field>
        </template>
        <v-date-picker
          id="stime-datepicker"
          v-model="queryDate"
          no-title
          locale="it-IT"
          :max="maxDate"
          :min="minDate"
          @input="changeDate()"
        ></v-date-picker>
      </v-menu>
    </v-row>
    <v-row>
      <v-radio-group
        dense
        id="radio-group-stime"
        v-model="wms"
        style="padding-left: 10px"
        @change="changeLayer()"
      >
        <v-radio
          label="Qualità dell'aria"
          value="Qualita_Aria"
          class="evidenza"
        ></v-radio>
        <v-radio label="Ozono (O3)" value="Stazioni_O3"></v-radio>
        <v-radio label="Biossido di azoto (NO2)" value="Stazioni_NO2"></v-radio>
        <v-radio label="Polveri sottili (PM10)" value="Stazioni_PM10"></v-radio>
        <v-radio
          label="Polveri sottili (PM2.5)"
          value="Stazioni_PM2_5"
        ></v-radio>
      </v-radio-group>
    </v-row>
    <v-row class="map-legend-row">
      <div class="col msg">
        <span v-if="wms != 'Qualita_Aria'">
          {{ legendMsg }}
        </span>
        <span v-if="wms == 'Qualita_Aria'" class="map-legend-row-qa">
          Qualità dell'aria definita in base alla<br />
          classe peggiore fra O3, NO2 e PM10
        </span>
      </div>
    </v-row>
    <v-row class="map-legend-row" v-if="wms != 'Qualita_Aria'">
      <div class="col msg">
        <span> MICROGRAMMI PER METRO CUBO μg/m3 </span>
      </div>
    </v-row>
    <v-row class="map-legend-row">
      <div
        class="map-legend-col"
        v-for="legend in legendRange"
        :key="legend.label"
      >
        {{ legend.value }}
        <div v-if="legend.label == 'Ottima'" class="o">Ottima</div>
        <div v-if="legend.label == 'Buona'" class="b">Buona</div>
        <div v-if="legend.label == 'Accettabile'" class="a">Accettabile</div>
        <div v-if="legend.label == 'Cattiva'" class="c">Cattiva</div>
        <div v-if="legend.label == 'Pessima'" class="p">Pessima</div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import config from "@/config/mappa-inquinanti";
import moment from "moment";
import { EventBus } from "../../EventBus";
import { GET_FEATURE } from "@/store/actions.type";

export default {
  name: "ControlStime",
  data() {
    let today = new moment();
    let max = moment(today).add(2, "days");
    let min = moment(today).subtract(15, "month");

    return {
      wms: "Qualita_Aria",
      showPicker: false,
      queryDate: today.format("YYYY-MM-DD"),
      maxDate: max.format("YYYY-MM-DD"),
      minDate: min.format("YYYY-MM-DD"),
      dateOpt: { year: "numeric", month: "numeric", day: "numeric" },
      legendRange_QualitaAria: [
        { value: "", label: "Ottima", bColor: "#5dc83b" },
        { value: "", label: "Buona", bColor: "#c4fc7c" },
        { value: "", label: "Accettabile", bColor: "#fffd55" },
        { value: "", label: "Cattiva", bColor: "#ea3324" },
        { value: "", label: "Pessima", bColor: "#771b7e" }
      ],
      legendRange_O3: [
        { value: "0-60", label: "Ottima", bColor: "#5dc83b" },
        { value: "61-84", label: "Buona", bColor: "#c4fc7c" },
        { value: "85-120", label: "Accettabile", bColor: "#fffd55" },
        { value: "121-240", label: "Cattiva", bColor: "#ea3324" },
        { value: ">240", label: "Pessima", bColor: "#771b7e" }
      ],
      legendRange_NO2: [
        { value: "0-100", label: "Ottima", bColor: "#5dc83b" },
        { value: "101-140", label: "Buona", bColor: "#c4fc7c" },
        { value: "141-200", label: "Accettabile", bColor: "#fffd55" },
        { value: "201-400", label: "Cattiva", bColor: "#ea3324" },
        { value: ">400", label: "Pessima", bColor: "#771b7e" }
      ],
      legendRange_PM10: [
        { value: "0-25", label: "Ottima", bColor: "#5dc83b" },
        { value: "26-35", label: "Buona", bColor: "#c4fc7c" },
        { value: "36-50", label: "Accettabile", bColor: "#fffd55" },
        { value: "51-100", label: "Cattiva", bColor: "#ea3324" },
        { value: ">100", label: "Pessima", bColor: "#771b7e" }
      ],
      legendRange_PM25: [
        { value: "0-15", label: "Ottima", bColor: "#5dc83b" },
        { value: "16-25", label: "Buona", bColor: "#c4fc7c" },
        { value: "26-30", label: "Accettabile", bColor: "#fffd55" },
        { value: "36-50", label: "Cattiva", bColor: "#ea3324" },
        { value: ">50", label: "Pessima", bColor: "#771b7e" }
      ],
      legendMsg: "",
      dataEmissione: undefined,
      controlsEnabled: true
    };
  },
  computed: {
    addDayDisabled: function () {
      let nextDay = new moment(this.queryDate).add(1, "days");
      let max = new moment(this.maxDate);
      return nextDay.isAfter(max) ? true : false;
    },
    subtractDayDisabled: function () {
      let prevDay = new moment(this.queryDate).subtract(1, "days");
      let min = new moment(this.minDate).subtract(1, "days");
      return prevDay.isBefore(min) ? true : false;
    },
    previsione: function () {
      let selectedDate = new moment(this.queryDate);
      let yesterday = new moment().subtract(1, "days");
      return selectedDate.isSameOrAfter(yesterday) ? true : false;
    },
    selectedDate: {
      get() {
        return moment(this.queryDate).format("DD/MM/YYYY");
      }
      /*set(newDate) {
        let newMoment = moment(newDate, "DD/MM/YYYY");
        this.queryDate = newMoment.format("YYYY-MM-DD");
        this.changeDate();
        return newDate;
      }*/
    },
    legendRange: function () {
      switch (this.wms) {
        case "Stazioni_O3":
          return this.legendRange_O3;
        case "Stazioni_NO2":
          return this.legendRange_NO2;
        case "Stazioni_PM10":
          return this.legendRange_PM10;
        case "Stazioni_PM2_5":
          return this.legendRange_PM25;
        default:
          return this.legendRange_QualitaAria;
      }
    },
    dataEmissioneDesc: function () {
      return this.dataEmissione != undefined
        ? "Emesse il " + moment(this.dataEmissione).format("DD/MM/YYYY")
        : "";
    }
  },
  methods: {
    moment: function (dateStr) {
      return moment(dateStr);
    },
    changeLayer() {
      this.getFeature(this.queryDate);
      if (this.previsione) {
        this.getFeatureForDataEmissione(this.queryDate);
      }
      this.$emit("layerChanged", this.wms);
      EventBus.$emit("layerChangedByStime", this.wms);
    },
    changeDate() {
      this.showPicker = false;
      //this.controlsEnabled = false;
      this.getFeature(this.queryDate);
      if (this.previsione) {
        this.getFeatureForDataEmissione(this.queryDate);
      }
      this.$emit("dateChanged", this.queryDate);
      EventBus.$emit("dateChanged", this.queryDate);
    },
    addDay() {
      if (!this.controlsEnabled || this.addDayDisabled) return;
      this.queryDate = new moment(this.queryDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.changeDate();
    },
    subtractDay() {
      if (!this.controlsEnabled || this.subtractDayDisabled) return;
      this.queryDate = new moment(this.queryDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      this.changeDate();
    },
    getFeature(queryDate) {
      var url = this.getFeatureUrl(queryDate);
      this.dataEmissione = undefined;
      this.$store
        .dispatch(GET_FEATURE, {
          url: url
        })
        .then((response) => {
          //var parser = require("xml2json");
          //var json = parser.toJson(response.data);
          let numberOfFeatures = this.getNumberOfFeatures(response.data);
          /*console.log(
            "Modellistica " +
              queryDate +
              " - numberOfFeatures: " +
              numberOfFeatures
          );*/
          if (numberOfFeatures === 0) {
            this.$emit("dataUnavailable", this.queryDate, this.wms);
          } else {
            this.$emit("dataReady", this.queryDate, this.wms);
          }
        })
        .catch((error) => {
          //console.log("Error", { error });
        });
    },
    getFeatureUrl: function (queryDate) {
      // Construct a GetFeatureInfo request URL given a point
      let filter =
        `<ogc:Filter xmlns:ogc="http://www.opengis.net/ogc"><ogc:PropertyIsEqualTo><ogc:PropertyName>data_previsione</ogc:PropertyName><ogc:Literal>` +
        queryDate +
        `</ogc:Literal></ogc:PropertyIsEqualTo></ogc:Filter>`;
      let params = {
        request: "GetFeature",
        service: "WFS",
        version: "1.1.0",
        typename: "Modellistica",
        maxfeatures: "510000",
        srsname: "urn:ogc:def:crs:EPSG::3857",
        filter: filter,
        resulttype: "hits"
      };
      let url = config.wfsAriaWebUrl;
      return url + L.Util.getParamString(params, url, true);
    },
    getNumberOfFeatures(data) {
      let numberOfFeatures = undefined;
      try {
        let index = data.indexOf("numberOfFeatures=");
        if (index > 0) {
          let dataCut = data.substring(index + 18);
          let index2 = dataCut.indexOf('"');
          numberOfFeatures = parseInt(dataCut.substring(0, index2));
        }
      } catch (e) {
        //console.log(e);
      }
      return numberOfFeatures;
    },
    getFeatureForDataEmissione(queryDate) {
      var url = this.getDataEmissioneFeatureUrl(queryDate);
      this.$store
        .dispatch(GET_FEATURE, {
          url: url
        })
        .then((response) => {
          //var parser = require("xml2json");
          //var json = parser.toJson(response.data);
          let dataEmissione = this.getDataEmissione(response.data);
          this.dataEmissione = dataEmissione;
          /*console.log(
            "Modellistica " + queryDate + " - dataEmissione: " + dataEmissione
          );*/
        })
        .catch((error) => {
          //console.log("Error", { error });
        });
    },
    getDataEmissioneFeatureUrl: function (queryDate) {
      // Construct a GetFeatureInfo request URL given a point
      let filter =
        `<ogc:Filter xmlns:ogc="http://www.opengis.net/ogc">
        <ogc:And>
          <ogc:PropertyIsEqualTo>
          <ogc:PropertyName>nome_comune</ogc:PropertyName>
          <ogc:Literal>TORINO</ogc:Literal>
          </ogc:PropertyIsEqualTo>
          <ogc:PropertyIsEqualTo>
          <ogc:PropertyName>data_previsione</ogc:PropertyName>
          <ogc:Literal>` +
        queryDate +
        `</ogc:Literal>
          </ogc:PropertyIsEqualTo>
        </ogc:And>
        </ogc:Filter>`;
      let params = {
        request: "GetFeature",
        service: "WFS",
        version: "1.1.0",
        typename: "Modellistica",
        maxfeatures: "1210",
        srsname: "urn:ogc:def:crs:EPSG::3857",
        filter: filter
      };
      let url = config.wfsAriaWebUrl;
      return url + L.Util.getParamString(params, url, true);
    },
    getDataEmissione(data) {
      let dataEmissione = undefined;
      try {
        let index = data.indexOf("<ms:data_emissione>");
        if (index > 0) {
          let dataCut = data.substring(index + 19);
          let index2 = dataCut.indexOf("</ms:data_emissione>");
          dataEmissione = dataCut.substring(0, index2);
        }
      } catch (e) {
        //console.log(e);
      }
      return dataEmissione;
    },
    getWms(layerName) {
      switch (layerName) {
        case "Stazioni_O3_A":
          return "Stazioni_O3";
        case "Stazioni_NO2_A":
          return "Stazioni_NO2";
        case "Stazioni_PM10_A":
          return "Stazioni_PM10";
        case "Stazioni_PM2_5_A":
          return "Stazioni_PM2_5";
        default:
          return this.wms;
      }
    }
  },
  mounted() {
    //Solo in Stime faccio il controllo getFeature al mount
    this.getFeature(this.queryDate);
    this.getFeatureForDataEmissione(this.queryDate);

    EventBus.$on("dateChanged", (newDate) => {
      this.queryDate = newDate;
    });
    EventBus.$on("layerChangedByMisure", (newLayer) => {
      this.wms = this.getWms(newLayer);
    });
    EventBus.$on("checkFeatureStime", () => {
      this.getFeature(this.queryDate);
      if (this.previsione) {
        this.getFeatureForDataEmissione(this.queryDate);
      }
    });
    EventBus.$on("enableControls", (newDate) => {
      this.controlsEnabled = true;
    });
  }
};
</script>
