<template>
  <v-container id="content">
    <v-row justify="space-between">
      <v-col cols="8" class="map-control-label"> Dati aggregati del </v-col>
      <v-col cols="4" class="d-flex justify-end">
        <a @click="subtractDay()">
          <v-icon v-if="!subtractDayDisabled" color="blue">
            mdi-chevron-left-circle
          </v-icon>
          <v-icon v-if="subtractDayDisabled" color="grey">
            mdi-chevron-left-circle
          </v-icon>
        </a>
        <a @click="addDay()">
          <v-icon v-if="!addDayDisabled" color="blue">
            mdi-chevron-right-circle
          </v-icon>
          <v-icon v-if="addDayDisabled" color="grey">
            mdi-chevron-right-circle
          </v-icon>
        </a>
      </v-col>
    </v-row>
    <v-row>
      <v-menu
        v-model="showPicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        z-index="2000"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            filled
            dense
            readonly
            aria-label="Imposta data"
            append-icon="mdi-calendar"
            v-on="on"
            :value="selectedDate"
            @click:append="showPicker = !showPicker"
          ></v-text-field>
        </template>
        <v-date-picker
          id="misure-datepicker"
          v-model="queryDate"
          no-title
          locale="it-IT"
          :max="maxDate"
          :min="minDate"
          @input="changeDate()"
        ></v-date-picker>
      </v-menu>
    </v-row>
    <v-row>
      <v-radio-group
        dense
        id="radio-group-misure"
        v-model="wms"
        style="padding-left: 10px"
        @change="changeLayer()"
      >
        <v-radio label="Ozono (O3)" value="Stazioni_O3_A"></v-radio>
        <v-radio label="Biossido di azoto (NO2)" value="Stazioni_NO2_A">
        </v-radio>
        <v-radio label="Polveri sottili (PM10)" value="Stazioni_PM10_A">
        </v-radio>
        <v-radio label="Polveri sottili (PM2.5)" value="Stazioni_PM2_5_A">
        </v-radio>
      </v-radio-group>
    </v-row>
    <v-row class="map-legend-row">
      <div class="col msg">
        {{ legendMsg }}
      </div>
    </v-row>
    <v-row class="map-legend-row">
      <div class="col msg">MICROGRAMMI PER METRO CUBO μg/m3</div>
    </v-row>
    <v-row class="map-legend-row">
      <div
        class="map-legend-col"
        v-for="legend in legendRange"
        :key="legend.label"
      >
        {{ legend.value }}
        <!--div v-bind:style="{ backgroundColor: legend.bColor }">
          <span
            v-bind:style="{
              color: legend.bColor == '#771b7e' ? 'white' : 'black',
              fontSize: '11px'
            }"
          >
            <strong>{{ legend.label }}</strong>
          </span>
        </!--div-->
        <div v-if="legend.label == 'Ottima'" class="o">Ottima</div>
        <div v-if="legend.label == 'Buona'" class="b">Buona</div>
        <div v-if="legend.label == 'Accettabile'" class="a">Accettabile</div>
        <div v-if="legend.label == 'Cattiva'" class="c">Cattiva</div>
        <div v-if="legend.label == 'Pessima'" class="p">Pessima</div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import config from "@/config/mappa-inquinanti";
import moment from "moment";
import { EventBus } from "../../EventBus";
import { GET_FEATURE } from "@/store/actions.type";

export default {
  name: "ControlMisure",
  data() {
    let today = new moment();
    let max = moment(today).subtract(1, "day");
    let min = moment(today).subtract(15, "month");

    return {
      wms: "Stazioni_O3_A",
      showPicker: false,
      queryDate: today.format("YYYY-MM-DD"),
      maxDate: max.format("YYYY-MM-DD"),
      minDate: min.format("YYYY-MM-DD"),
      dateOpt: { year: "numeric", month: "numeric", day: "numeric" },
      legendRange_O3: [
        { value: "0-60", label: "Ottima", bColor: "#5dc83b" },
        { value: "61-84", label: "Buona", bColor: "#c4fc7c" },
        { value: "85-120", label: "Accettabile", bColor: "#fffd55" },
        { value: "121-240", label: "Cattiva", bColor: "#ea3324" },
        { value: ">240", label: "Pessima", bColor: "#771b7e" }
      ],
      legendRange_NO2: [
        { value: "0-100", label: "Ottima", bColor: "#5dc83b" },
        { value: "101-140", label: "Buona", bColor: "#c4fc7c" },
        { value: "141-200", label: "Accettabile", bColor: "#fffd55" },
        { value: "201-400", label: "Cattiva", bColor: "#ea3324" },
        { value: ">400", label: "Pessima", bColor: "#771b7e" }
      ],
      legendRange_PM10: [
        { value: "0-25", label: "Ottima", bColor: "#5dc83b" },
        { value: "26-35", label: "Buona", bColor: "#c4fc7c" },
        { value: "36-50", label: "Accettabile", bColor: "#fffd55" },
        { value: "51-100", label: "Cattiva", bColor: "#ea3324" },
        { value: ">100", label: "Pessima", bColor: "#771b7e" }
      ],
      legendRange_PM2_5: [
        { value: "0-15", label: "Ottima", bColor: "#5dc83b" },
        { value: "16-25", label: "Buona", bColor: "#c4fc7c" },
        { value: "26-30", label: "Accettabile", bColor: "#fffd55" },
        { value: "36-50", label: "Cattiva", bColor: "#ea3324" },
        { value: ">50", label: "Pessima", bColor: "#771b7e" }
      ]
    };
  },
  computed: {
    addDayDisabled: function () {
      let nextDay = new moment(this.queryDate).add(1, "days");
      let max = new moment(this.maxDate);
      return nextDay.isAfter(max) ? true : false;
    },
    subtractDayDisabled: function () {
      let prevDay = new moment(this.queryDate).subtract(1, "days");
      let min = new moment(this.minDate).subtract(1, "days");
      return prevDay.isBefore(min) ? true : false;
    },
    selectedDate: function () {
      return moment(this.queryDate).format("DD/MM/YYYY");
    },
    legendRange: function () {
      switch (this.wms) {
        case "Stazioni_O3_A":
          return this.legendRange_O3;
        case "Stazioni_NO2_A":
          return this.legendRange_NO2;
        case "Stazioni_PM10_A":
          return this.legendRange_PM10;
        case "Stazioni_PM2_5_A":
          return this.legendRange_PM2_5;
        default:
          return [];
      }
    },
    legendMsg: function () {
      switch (this.wms) {
        case "Stazioni_O3_A":
          return "Valore massimo giornaliero medie mobili su 8 ore";
        case "Stazioni_NO2_A":
          return "Valore della media oraria massima";
        case "Stazioni_PM10_A":
          return "Valore medio giornaliero";
        case "Stazioni_PM2_5_A":
          return "Valore medio giornaliero";
        default:
          return [];
      }
    }
  },
  methods: {
    changeLayer() {
      this.getFeature(this.queryDate);
      this.$emit("layerChanged", this.wms);
      EventBus.$emit("layerChangedByMisure", this.wms);
    },
    changeDate() {
      this.showPicker = false;
      this.getFeature(this.queryDate);
      let error = undefined;
      if (this.isSameOrAfterToday(this.queryDate)) {
        error = "dateError";
      }
      this.$emit("dateChanged", this.queryDate, error);
      EventBus.$emit("dateChanged", this.queryDate, error);
    },
    addDay() {
      if (this.addDayDisabled) return;
      this.queryDate = new moment(this.queryDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.changeDate();
    },
    subtractDay() {
      if (this.subtractDayDisabled) return;
      //console.log(this.queryDate);
      this.queryDate = new moment(this.queryDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      this.changeDate();
    },
    isSameOrAfterToday(queryDate) {
      let today = new moment().startOf("day");
      let newDay = new moment(queryDate);
      return newDay.isSameOrAfter(today);
    },
    getFeature(queryDate) {
      if (this.isSameOrAfterToday(queryDate)) {
        let today = new moment().startOf("day");
        let newDay = new moment(this.queryDate);
        let error = "";
        if (newDay.isSame(today)) {
          error = "todayError";
        } else if (newDay.isAfter(today)) {
          error = "futureDayError";
        }
        this.$emit("dataMisureError", this.queryDate, error);
        return;
      }
      var url = this.getFeatureUrl(queryDate);
      this.$store
        .dispatch(GET_FEATURE, {
          url: url
        })
        .then((response) => {
          //var parser = require("xml2json");
          //var json = parser.toJson(response.data);
          let numberOfFeatures = this.getNumberOfFeatures(response.data);
          /*console.log(
            "Aggregazioni " +
              queryDate +
              " - numberOfFeatures: " +
              numberOfFeatures
          );*/
          if (numberOfFeatures === 0) {
            this.$emit("dataUnavailable", this.queryDate, this.wms);
          } else {
            this.$emit("dataReady", this.queryDate, this.wms);
          }
        })
        .catch((error) => {
          //console.log("Error", { error });
        });
    },
    getFeatureUrl: function (queryDate) {
      // Construct a GetFeatureInfo request URL given a point
      let filter =
        `<ogc:Filter xmlns:ogc="http://www.opengis.net/ogc"><ogc:PropertyIsEqualTo><ogc:PropertyName>data</ogc:PropertyName><ogc:Literal>` +
        queryDate +
        `</ogc:Literal></ogc:PropertyIsEqualTo></ogc:Filter>`;
      let params = {
        request: "GetFeature",
        service: "WFS",
        version: "1.1.0",
        typename: "Aggregazioni",
        maxfeatures: "510000",
        srsname: "urn:ogc:def:crs:EPSG::3857",
        filter: filter,
        resulttype: "hits"
      };
      let url = config.wfsAriaWebUrl;
      return url + L.Util.getParamString(params, url, true);
    },
    getNumberOfFeatures(data) {
      let numberOfFeatures = undefined;
      try {
        let index = data.indexOf("numberOfFeatures=");
        if (index > 0) {
          let dataCut = data.substring(index + 18);
          let index2 = dataCut.indexOf('"');
          numberOfFeatures = parseInt(dataCut.substring(0, index2));
        }
      } catch (e) {
        //console.log(e);
      }
      return numberOfFeatures;
    },
    getWms(layerName) {
      switch (layerName) {
        case "Stazioni_O3":
          return "Stazioni_O3_A";
        case "Stazioni_NO2":
          return "Stazioni_NO2_A";
        case "Stazioni_PM10":
          return "Stazioni_PM10_A";
        case "Stazioni_PM2_5":
          return "Stazioni_PM2_5_A";
        case "Qualita_Aria":
          return "Stazioni_O3_A";
        default:
          return this.wms;
      }
    }
  },
  mounted() {
    //this.getFeature(this.queryDate);

    EventBus.$on("dateChanged", (newDate) => {
      this.queryDate = newDate;
    });
    EventBus.$on("layerChangedByStime", (newLayer) => {
      this.wms = this.getWms(newLayer);
    });
    EventBus.$on("checkFeatureMisure", () => {
      this.getFeature(this.queryDate);
    });
  }
};
</script>
